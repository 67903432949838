import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ReactSquadLogo from 'assets/icons/React-squad-logo.png';
import { Button } from 'components/button/button-component';
import { Typography } from 'components/typography/typography-component';
import Image from 'next/image';
import { Fragment } from 'react';

import { ModalProps } from './types';

export default function ReactSquadAdModal({ open, onClose }: ModalProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-white bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto drop-shadow-2xl">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-24">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="focus:ring-indigo-500 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={() => onClose(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div>
                  <div className="flex flex-row gap-x-5">
                    <Image
                      src={ReactSquadLogo}
                      alt="React Squads"
                      width={100}
                      height={100}
                      className="h-20 w-20 rounded-lg bg-slate-400 object-cover lg:h-24 lg:w-24"
                    />
                    <div>
                      <Typography className="self-center" variant="h3">
                        ReactSquad
                      </Typography>
                      <Typography variant="h5" className="text-gray-700">
                        Providing React developers to fast-growing startups in
                        &lt; 7 days.
                      </Typography>
                    </div>
                  </div>

                  <div className="mt-10">
                    <ul className="list-disc pl-5 text-lg">
                      <li className="mb-2 ">
                        In-House team of engineers hyper-focused on React.js
                      </li>
                      <li className="mb-2">
                        100% risk-free 30-day trial period
                      </li>
                      <li className="">Transparent Pricing: $80/hour</li>
                    </ul>
                  </div>

                  <div>
                    <a href={'/react-squad'} rel="noreferrer">
                      <Button
                        className="mt-6 flex flex-row rounded-xl py-3"
                        color="blue"
                        variant="filled"
                        fullwidth
                      >
                        <span className="mr-2">Learn More</span>
                      </Button>
                    </a>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
