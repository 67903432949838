import { Button } from 'components/button/button-component';
import NextImage from 'components/image/image';
import { Typography } from 'components/typography/typography-component';
import { htmlToText } from 'html-to-text';
import Link from 'next/link';

import { PostType } from './post-types';

export function Post({
  title,
  img = '',
  link,
  count,
  description,
  category,
  categoryLink,
  variant = 'featured',
}: PostType) {
  if (variant === 'featured') {
    return (
      <Link href={link}>
        <div className="w-full">
          <div className="relative flex h-80 items-center justify-center rounded px-6 md:h-[400px] lg:h-[600px]">
            <div className="flex overflow-hidden rounded shadow-sm">
              <NextImage
                fill
                style={{ border: '2px solid #f2f2f2', borderRadius: '10px' }}
                src={img}
                alt={title}
                imgClassName="object-cover object-top"
                unoptimized
              />
            </div>
          </div>
          <Typography
            variant="h3"
            className="my-5 overflow-ellipsis text-[1.7rem]"
          >
            {title}
          </Typography>
          <Typography
            className="flex-nowrap overflow-hidden text-ellipsis"
            variant="small"
          >
            {description}
          </Typography>
        </div>
      </Link>
    );
  }

  if (variant === 'count') {
    return (
      <Link href={link}>
        <div className="w-full">
          <div className="relative flex h-[250px] items-center justify-center rounded px-6">
            <div className="flex overflow-hidden rounded shadow-sm">
              <NextImage
                fill
                src={img}
                style={{ border: '2px solid #f2f2f2', borderRadius: '10px' }}
                alt={title}
                imgClassName="object-cover object-top"
                unoptimized
              />
            </div>
          </div>
          <Typography variant="h4" className="my-3 text-[1.5rem]">
            {count && <span className="text-primary">{`${count} `}</span>}{' '}
            {title}
          </Typography>
          <Typography
            className="h-16 flex-nowrap overflow-hidden text-ellipsis"
            variant="small"
          >
            {description}...
          </Typography>
        </div>
      </Link>
    );
  }

  if (variant === 'horizontal_count') {
    return (
      <Link
        href={link}
        className="flex w-full flex-row items-center justify-between"
      >
        <div className="flex w-3/4 flex-row justify-start">
          {count && (
            <div className="mr-3 flex h-10 w-10 items-center justify-center rounded-full border-2 px-4">{`${count} `}</div>
          )}
          <Typography
            variant="h6"
            className="max-h-12 overflow-hidden text-ellipsis font-default"
          >
            {title}
          </Typography>
        </div>

        <div className="relative ml-5 flex h-[70px] w-1/4 items-center justify-center rounded bg-slate-50 px-6">
          <div className="flex overflow-hidden rounded-md shadow-sm">
            <NextImage
              fill
              src={img}
              style={{ border: '2px solid #f2f2f2', borderRadius: '10px' }}
              alt={title}
              imgClassName="object-cover object-top"
              unoptimized
            />
          </div>
        </div>
      </Link>
    );
  }

  if (variant === 'sidebar') {
    return (
      <div className="w-full">
        <Link href={link}>
          <div className="relative flex h-[150px] items-center justify-center rounded px-6">
            <div className="flex overflow-hidden rounded shadow-sm">
              <NextImage
                fill
                src={img}
                style={{ border: '2px solid #f2f2f2', borderRadius: '10px' }}
                alt={title}
                imgClassName="object-cover object-top"
                unoptimized
              />
            </div>
          </div>
        </Link>

        <Link href={link}>
          <Typography variant="h5" className="my-2">
            {title}
          </Typography>
        </Link>

        <Typography
          className="prose max-h-10 min-h-8 flex-nowrap overflow-hidden text-ellipsis"
          variant="small"
        >
          {description}...
        </Typography>

        <Link href={categoryLink ?? ''}>
          <span className="mt-3 inline-flex items-center rounded-2xl bg-slate-100 px-4 py-2 text-sm font-medium text-slate-800 lg:rounded-xl">
            {htmlToText(category || '')}
          </span>
        </Link>
      </div>
    );
  }

  if (variant === 'horizontal_topics') {
    return (
      <div className="flex w-full flex-row items-start">
        <div className="relative h-20 w-2/6 rounded px-6">
          <div className="flex overflow-hidden rounded shadow-sm">
            <NextImage
              fill
              src={img}
              style={{ border: '2px solid #f2f2f2', borderRadius: '10px' }}
              alt={title}
              imgClassName="object-cover object-top"
              unoptimized
            />
          </div>
        </div>
        <Link href={link}>
          <div className="ml-3 flex flex-col justify-start">
            <Typography
              variant="h4"
              className="mb-1 max-h-9 overflow-hidden text-ellipsis"
            >
              {title}
            </Typography>
            <Typography
              className="min-h-16 flex-nowrap overflow-hidden text-ellipsis"
              variant="small"
            >
              {description}
            </Typography>
          </div>
        </Link>
      </div>
    );
  }

  if (variant === 'related') {
    return (
      <div className="w-full">
        <Link className="no-underline" href={link}>
          <div className="aspect-w-1 aspect-h-1 lg:aspect-none relative flex h-60 min-h-60 w-full items-center justify-center overflow-hidden rounded-2xl sm:h-60">
            <div className="flex overflow-hidden rounded shadow-sm">
              <NextImage
                fill
                src={img}
                style={{ border: '2px solid #f2f2f2', borderRadius: '10px' }}
                alt={title}
                imgClassName="object-cover object-top"
                unoptimized
              />
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <Typography variant="h3" className="text-md my-3 sm:text-xl">
              {title}
            </Typography>

            <Button
              as="link"
              fullwidth
              variant="outlined"
              className="my-6 no-underline"
            >
              Read More
            </Button>
          </div>
        </Link>
      </div>
    );
  }

  if (variant === 'horizontal_categories') {
    return (
      <Link href={link}>
        <div className="w-full gap-4 border-b border-gray-300 pb-8 pt-5 sm:flex">
          <div className="relative h-[250px] min-w-[200px] md:min-w-[330px]">
            <NextImage
              fill
              src={img}
              style={{ border: '2px solid #f2f2f2', borderRadius: '10px' }}
              alt={title}
              imgClassName="object-cover object-top"
              unoptimized
            />
          </div>
          <div className="">
            <Typography variant="h4" className="my-3 line-clamp-2">
              {title}
            </Typography>
            <Typography
              className="flex-nowrap overflow-hidden text-ellipsis"
              variant="paragraph"
            >
              {description}
            </Typography>
          </div>
        </div>
      </Link>
    );
  }

  if (variant === 'homepage_graphic') {
    return (
      <Link href={link}>
        <div className="h-full w-full p-6">
          <div className="relative h-full min-w-full">
            <NextImage
              src={img}
              alt={title}
              imgClassName="object-cover object-top"
              height={100}
              width={240}
              unoptimized
            />
          </div>
          <Typography variant="h4" className="my-3 line-clamp-2">
            {title}
          </Typography>
          <Typography
            className="h-48 flex-nowrap overflow-hidden text-ellipsis text-sm"
            variant="paragraph"
          >
            {description}
          </Typography>
        </div>
      </Link>
    );
  }

  return <></>;
}
