import WithPopCondition from 'HOCs/with-popup-condition';
import { useState } from 'react';
import { useTimeoutFn } from 'react-use';

import ReactSquadAdModal from './react-sqaud-modal';

const ReactSquadModalAd = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShowModal = () => {
    const value = window.sessionStorage.getItem('hasShownReactSquadsPopup');
    if (value === 'true') {
      return;
    }
    setShowModal(true);
    window.sessionStorage.setItem('hasShownReactSquadsPopup', 'true');
  };

  useTimeoutFn(() => {
    handleShowModal();
  }, 30_000);

  const handleCloseModal = () => {
    window.sessionStorage.setItem('hasShownReactSquadsPopup', 'true');
    setShowModal(false);
  };

  return <ReactSquadAdModal open={showModal} onClose={handleCloseModal} />;
};

export default WithPopCondition(ReactSquadModalAd);
